<template>
  <div data-movie-carousel>
    <Carousel
      :slides
      :slide-duration
      :autoplay
      :infinite
      :render-above-the-fold
      :link-params
    />
  </div>
</template>

<script setup lang="ts">
import type { Movie, MovieInterface, LinkParam } from '#gql/default'

export interface MovieCarouselProps {
  renderAboveTheFold: boolean
  infinite?: boolean
  autoplay?: boolean
  slideDuration?: number
  linkParams: LinkParam[]
  movies: Movie[] | MovieInterface[]
}

const props = withDefaults(defineProps<MovieCarouselProps>(), {
  infinite: false,
  autoplay: false,
  slideDuration: 7000,
})

const slides = computed(() =>
  props.movies.map((movie) => ({ ...movie, type: CAROUSEL_SLIDE_TYPE.MOVIE }))
)

defineOptions({
  name: 'MovieCarousel',
})
</script>
