<template>
  <div
    data-carousel
    :data-animating="isAnimating ? '' : undefined"
    class="@container/carousel group relative"
    :class="cssClasses.container"
  >
    <div class="flex h-full">
      <div :ref="getWrapperRef" class="w-full lg:overflow-hidden">
        <div
          v-if="slides.length"
          class="flex flex-nowrap"
          :class="{
            'transition-transform duration-500 ease-in-out': isAnimating,
          }"
          :style="`transform: translateX(${position}px)`"
          @touchstart.passive="stopAutoplay(), onTouchStart($event)"
          @touchmove.passive="onTouchMove"
          @touchend.passive="onTouchEnd"
        >
          <div v-if="infinite" :class="cssClasses.slide">
            <CarouselSlide
              :key="prefixSlide"
              :slide="slides?.[prefixSlide]"
              :render-above-the-fold="false"
              :render-trailer="renderTrailer"
              :hide-title="displayImagesOnly || hideTitle"
              :hide-description="displayImagesOnly || hideDescription"
              :hide-link="displayImagesOnly || hideLink"
            />
          </div>
          <div
            v-for="(slide, index) in slides.slice(0, lastSlideToPreload)"
            :key="slide.title"
            :class="cssClasses.slide"
          >
            <CarouselSlide
              :slide="slide"
              :index="index"
              :render-above-the-fold="index === 0 && renderAboveTheFold"
              :render-trailer="renderTrailer"
              :active="currentIndex === index"
              :hide-title="displayImagesOnly || hideTitle"
              :hide-description="displayImagesOnly || hideDescription"
              :hide-link="displayImagesOnly || hideLink"
            />
          </div>
          <div v-if="infinite" :class="cssClasses.slide">
            <CarouselSlide
              :key="suffixSlide"
              :slide="slides?.[suffixSlide]"
              :render-above-the-fold="false"
              :render-trailer="renderTrailer"
              :hide-title="displayImagesOnly || hideTitle"
              :hide-description="displayImagesOnly || hideDescription"
              :hide-link="displayImagesOnly || hideLink"
            />
          </div>
        </div>
      </div>
    </div>

    <div v-if="slides.length > 1" class="flex items-center py-1 pl-5 sm:pl-0">
      <div
        class="sm:-mr-30 flex flex-grow flex-wrap space-x-2 sm:justify-center"
      >
        <div
          v-for="(_, i) in slides.length"
          :key="i"
          class="h-2 w-2 rounded-full"
          :class="{
            'bg-neutral': i !== currentIndex,
            'bg-primary': i === currentIndex,
          }"
        />
      </div>

      <nav
        class="flex w-12 items-center justify-between space-x-1 px-1 sm:w-36"
      >
        <Button
          :icon="SVG_ICON.CHEVRON_LEFT"
          :theme="BUTTON_THEME.TERTIARY"
          :title="t('btn.prev')"
          :class="cssClasses.buttonPrev"
          @click="stopAutoplay(), slideLeft(), activeElement?.blur()"
        />

        <Button
          :key="
            isPlaying
              ? SVG_ICON.PAUSE_CIRCLE_OUTLINE
              : SVG_ICON.PLAY_CIRCLE_OUTLINE
          "
          :theme="BUTTON_THEME.TERTIARY"
          :icon="
            isPlaying
              ? SVG_ICON.PAUSE_CIRCLE_OUTLINE
              : SVG_ICON.PLAY_CIRCLE_OUTLINE
          "
          :title="isPlaying ? t('btn.stop') : t('btn.play')"
          @click="toggleAutoplay()"
        />

        <Button
          :icon="SVG_ICON.CHEVRON_RIGHT"
          :theme="BUTTON_THEME.TERTIARY"
          :title="t('btn.next')"
          :class="cssClasses.buttonNext"
          @click="stopAutoplay(), slideRight(), activeElement?.blur()"
        />
      </nav>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'

interface Props {
  slides: CarouselSlide[]
  renderAboveTheFold: boolean
  renderTrailer?: boolean
  hideTitle?: boolean
  hideDescription?: boolean
  hideLink?: boolean
  infinite: boolean
  autoplay: boolean
  activeSlide?: number
  slideDuration: number
  classes?: {
    slide?: string | object | []
    container?: string | object | []
    buttonPrev?: string | object | []
    buttonNext?: string | object | []
  }
}

const { t } = useI18n()
const activeElement = useActiveElement()
const props = withDefaults(defineProps<Props>(), {
  hideLink: false,
  hideTitle: false,
  activeSlide: 0,
  renderTrailers: false,
  hideDescription: false,
  slides: () => [],
  classes: () => ({}),
})

const displayImagesOnly = computed(() =>
  props.slides.every(
    (slide) => !slide.title && !slide.description && !slide.link?.to
  )
)

const cssClasses = computed(() => {
  const { slide, container, buttonPrev, buttonNext } = props.classes

  return {
    slide: slide ?? 'flex shrink-0 basis-full px-0 sm:px-0',
    container: container ?? 'px-0 sm:px-0 overflow-hidden',
    buttonPrev: buttonPrev ?? 'hidden sm:flex',
    buttonNext: buttonNext ?? 'hidden sm:flex',
  }
})

const slidesVisible = ref(1)
const totalSlides = computed(() => props.slides.length - 1)

onMounted(() => {
  slideTo(props.activeSlide, false)
})

const {
  getWrapperRef,
  currentIndex,
  position,
  prefixSlide,
  suffixSlide,
  isPlaying,
  isAnimating,
  onTouchEnd,
  onTouchMove,
  onTouchStart,
  slideLeft,
  slideRight,
  slideTo,
  lastSlideToPreload,
  stopAutoplay,
  toggleAutoplay,
} = useCarousel({
  slidesVisible,
  autoplay: props.autoplay,
  infinite: props.infinite,
  duration: props.slideDuration,
  slides: {
    preload: 2,
    total: totalSlides,
  },
})

defineOptions({
  name: 'Carousel',
})
</script>

<i18n>
de:
  btn:
    next: "Weiter"
    prev: "Zurück"
    play: "Wiedergabe starten"
    stop: "Wiedergabe stoppen"
es:
  btn:
    next: "Siguiente"
    prev: "Anterior"
    play: "Reproducir"
    stop: "Detener"
</i18n>
