<template>
  <CarouselImage
    :image="slide.image"
    :image-format="IMAGE_FORMAT.LANDSCAPE"
    :image-config="imageConfig"
  />
  <div
    v-if="!hideTitle || !hideDescription || !hideLink"
    :style="`--slide-background: ${bgColor}; --slide-text: ${textColor};`"
    class="space-y-4 p-4 transition-all duration-700 ease-in-out sm:absolute sm:bottom-8 sm:left-8 sm:w-auto sm:min-w-[20rem] sm:max-w-md sm:rounded-b-lg sm:rounded-t-lg sm:bg-[var(--slide-background)] sm:shadow-lg sm:backdrop-blur-sm"
    :class="{
      'sm:hidden': !slide.title && !slide.description && !slide.link?.to,
    }"
  >
    <div
      v-if="!hideTitle && slide.title"
      :class="cssClasses.title"
      v-text="slide.title"
    />
    <div
      v-if="!hideDescription && slide.description"
      :class="cssClasses.description"
      v-text="slide.description"
    />

    <NuxtLink
      v-if="!hideLink && slide.link?.to"
      :to="slide.link.to"
      :title="slide.link.title"
      :target="slide.link.target"
      :class="cssClasses.link"
    >
      {{ slide.link.title }}
    </NuxtLink>
  </div>
</template>

<script lang="ts" setup>
interface Props {
  slide: CarouselSlide
  index?: number | null
  hideTitle: boolean
  hideDescription: boolean
  hideLink: boolean
  renderAboveTheFold: boolean
  classes?: {
    link?: string | object | []
    title?: string | object | []
    description?: string | object | []
  }
}

const props = withDefaults(defineProps<Props>(), {
  classes: () => ({}),
  index: 0,
})

const emit = defineEmits(['touchstart'])

const { bgColor, textColor } = useGetReadableColorCombination({
  bgColors: props.slide.image.colors,
  textColors: ['rgb(255,255,255)', 'rgb(0,0,0)'], //@TODO: use theme colors color-light and color-dark
  alpha: 0.75,
})

const cssClasses = computed(() => {
  const { link, title, description } = props.classes

  return {
    title: title ?? 'text-lg font-medium sm:text-[var(--slide-text)]',
    description: description ?? 'text-base sm:text-[var(--slide-text)]',
    link:
      link ??
      'button button-base button-secondary sm:!text-[var(--slide-text)] sm:!border-[var(--slide-text)]',
  }
})

const config = useAppConfig()
const imageConfig = computed(() => ({
  ...config.images.gallery.carousel,
  loading: props.renderAboveTheFold ? IMAGE_LOADING.EAGER : IMAGE_LOADING.LAZY,
}))

defineOptions({
  name: 'CarouselSlideImage',
})
</script>
